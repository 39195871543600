import { handleStartSpeechRequestTimerPanel } from "../../functions/functions-frank-words";
import {  handleCloseScreenOpenSpeechRequestOnTimer, handleSelectScreensOnTimer} from "../../functions/functions-quoruns";
import { handleCounter, handleSetCounter, handleSetDescStatus, handleVerifyTimeStamp } from "../../functions/functions-timers";

let { sectionPanelFrankWordSpeechRequest } = handleSelectScreensOnTimer();
let interval;

addEventListener('load', function () {
    if (sectionPanelFrankWordSpeechRequest.dataset.speech_request_started) {
        handleCloseScreenOpenSpeechRequestOnTimer();
    }
});


if(sectionPanelFrankWordSpeechRequest.dataset.speech_request_started){
    handleVerifyTimeStamp('/speech-request/syncronized-seconds/request').then(function(){
        interval = setInterval(() => handleCounter('SPEECH_REQUEST_CALL'), 1000);
    });
}
window.Echo.channel("speech-request-person-called")
    .listen(".speech-request-person-called", async (e) => {
    window.location.reload();
});

window.Echo.channel('stop-time-speech-request')
    .listen('.stop-time-speech-request', () => {
        window.location.reload();
    });

window.Echo.channel("synchronized-time-speech-request")
.listen(".synchronized-time-speech-request", (e) => {
    if (e.synchronizedSeconds < 0) {
        handleSetDescStatus(false)
        handleSetCounter(-(e.synchronizedSeconds)); 
    }else{
        handleSetDescStatus(true)
        handleSetCounter(e.synchronizedSeconds); 
    }
});